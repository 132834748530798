.header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111111;
}

.header__shrink {
  background: #14141f;
  box-shadow: 2px 2px 4px -2px #e250e5;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav__list {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
  margin-bottom: 0;
}

.nav__item a {
  text-decoration: none;
  color: #fff;
}

.nav__item a:hover {
  color: #fff;
}

.active {
  color: #e250e5 !important;
}

.logo h2 {
  background: linear-gradient(-45deg, #e250e5, #4b50e6, #e250e5, #4b50e6);
  background-size: 100% 100%;
  background-clip: text;
  -webkit-text-stroke: 3px transparent;
  -webkit-text-fill-color: #14141f;
  margin-bottom: 0;
}

.nav__right .btn {
  padding: 7px 25px;
  border: 1px solid #5142fc;
  border-radius: 50px;
}

.nav__right .btn a {
  color: #fff;
  text-decoration: none;
  font-size: 0.8rem;
}

.nav__right .btn span i {
  color: #fff;
}

.nav__right .btn a:hover {
  color: #fff;
}

.mobile__menu {
  color: #fff;
  font-size: 1.3rem;
  display: none;
}

@media only screen and (max-width: 768px) {
  .nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#14141f83, #14141f83);
    z-index: 222222;
    display: none;
  }

  .active__menu {
    display: block;
  }
  .nav__list {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #343444;
    z-index: 333333;
    flex-direction: column;
    padding-top: 40px;
  }

  .mobile__menu {
    display: block;
  }

  .btn {
    padding: 4px 25px !important;
  }

  .btn a {
    font-size: 0.7rem !important;
  }
}
