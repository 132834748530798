@media only screen and (max-width: 992px) {
  .nft__title {
    font-size: 0.8rem !important;
  }

  .creator__info h6 {
    font-size: 0.6rem !important;
  }

  .creator__info p {
    font-size: 0.7rem !important;
  }

  .bid__btn {
    padding: 4px 20px !important;
    font-size: 0.6rem !important;
  }
  .history__link a {
    font-size: 0.6rem !important;
  }
}
