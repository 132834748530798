.single__step__item {
  background: #343444;
  border-radius: 5px;
  padding: 30px;
}

.step__title {
  color: #fff;
}

.step__item__content h5 a {
  text-decoration: none;
  color: #fff;
}

.single__step__item span i {
  color: #fff;
  padding: 15px;
  font-size: 1.5rem;
  background: #5142fc;
  border-radius: 5px;
}

.step__item__content {
  margin-top: 20px !important;
}

@media only screen and (max-width: 992px) {
  .step__item__content h5 a {
    font-size: 1rem;
  }

  .single__step__item p {
    font-size: 0.8rem;
    line-height: 25px;
  }
}
