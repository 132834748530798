@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #14141f !important;
  font-family: "Roboto", sans-serif;
}

h1,
h2 {
  color: #fff;
  font-weight: 500;
  font-size: 2rem;
}

p {
  font-weight: 400;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.772);

  line-height: 35px;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

section,
footer {
  padding: 60px 0px;
}

/* =========== custom scroll-bar ========= */

::-webkit-scrollbar {
  width: 10px;
  background: #343444;
}

::-webkit-scrollbar-thumb {
  border-radius: 2em;
  background: linear-gradient(-45deg, #e250e5, #4b50e6, #e250e5, #4b50e6);
}

.hero__section {
  margin-top: 120px;
}

.hero__content h2 {
  line-height: 55px;
  font-size: 2.5rem;
}

.hero__content h2 span {
  background: linear-gradient(-45deg, #e250e5, #4b50e6, #e250e5, #4b50e6);
  background-size: 100% 100%;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-stroke: 3px transparent;
  -webkit-text-fill-color: #14141f;
  margin-left: 7px !important;
  display: inline-block;
}

.hero__btns {
  margin-top: 40px;
}

.explore__btn,
.create__btn {
  border: none;
  outline: none;
  padding: 7px 25px !important;
  border-radius: 50px !important;
  border: 1px solid #5142fc !important;
  font-size: 0.9rem;
  background: transparent;
}

.explore__btn i,
.create__btn i {
  color: #fff;
  font-size: 1rem;
}

.explore__btn a,
.create__btn a {
  text-decoration: none;
  color: #fff;
}

.hero__btns button a:hover {
  color: #fff;
}

.hero__img {
  width: 500px;
  margin-left: auto;
}

.hero__img img {
  border-radius: 7px;
}

@media only screen and (max-width: 992px) {
  .hero__img {
    width: 300px;
  }

  .hero__section {
    margin-top: 90px;
  }

  .hero__content h2 {
    font-size: 1.5rem;
    line-height: 40px;
  }

  .hero__content p {
    font-size: 0.8rem;
    line-height: 25px;
  }

  .create__btn,
  .explore__btn {
    padding: 4px 25px !important;
  }

  .create__btn a,
  .explore__btn a {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 576px) {
  .hero__img {
    width: 100%;
    margin: auto;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 992px) {
  .nft__title {
    font-size: 0.8rem !important;
  }

  .creator__info h6 {
    font-size: 0.6rem !important;
  }

  .creator__info p {
    font-size: 0.7rem !important;
  }

  .bid__btn {
    padding: 4px 20px !important;
    font-size: 0.6rem !important;
  }
  .history__link a {
    font-size: 0.6rem !important;
  }
}

.modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #14141f8a;
  z-index: 4444444;
}

.single__modal {
  position: relative;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #343444 !important;
  border-radius: 5px;
  padding: 30px;
  z-index: 5555555 !important;
  cursor: pointer;
}

.single__modal h6 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.single__modal .money {
  color: #fff;
  font-weight: 500;
  font-size: 1.1rem;
}

.input__item input {
  width: 100%;
  border: 1px solid rgba(221, 221, 221, 0.274);
  background: transparent;
  color: #fff;
  padding: 9px 25px;
  border-radius: 5px;
  outline: none;
  font-size: 0.8rem;
}

.input__item h6 {
  font-size: 0.9rem;
  color: #fff;
}

.place__bid-btn {
  padding: 7px 25px;
  border: none;
  outline: none;
  width: 120px;
  margin: auto;
  background: #5142fc;
  color: #fff;
  border-radius: 50px;
  font-size: 0.8rem;
  display: block;
  margin-top: 15px;
}

.close__modal {
  position: absolute;
  top: -2px;
  right: -2px;
  z-index: 6666666;
}

.close__modal i {
  padding: 5px;
  background: #5142fc;
  color: #fff;
  border-radius: 50%;
}

@media only screen and (max-width: 992px) {
  .single__modal {
    width: 300px;
    height: auto;
  }

  .single__modal h6 {
    font-size: 0.9rem;
  }
  .single__modal p {
    font-size: 0.8rem;
    margin-bottom: 0;
  }

  .money {
    font-size: 0.8rem !important;
  }

  .input__item h6 {
    font-size: 0.7rem;
  }

  .input__item input {
    padding: 5px 20px !important;
    font-size: 0.7rem;
  }
}

.single__nft__card {
  padding: 20px;
  background: #343444;
  border-radius: 10px;
}

.nft__img img {
  border-radius: 10px;
}

.nft__img {
  margin-bottom: 15px;
}

.nft__title {
  margin-bottom: 15px;
}

.nft__title a {
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
}

.nft__title a:hover {
  color: #fff;
}

.creator__img {
  width: 40px;
  height: 40px;
}

.creator__info h6 {
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.772);

  margin-bottom: 0;
  font-weight: 300;
}

.creator__info p {
  font-size: 0.9rem;
  color: #fff;

  margin-bottom: 0;
  font-weight: 500;
}

.bid__btn {
  border: none;
  outline: none;
  padding: 5px 22px;
  background: transparent;
  border: 1px solid #5142fc;
  font-size: 0.9rem;
  color: #fff;
  border-radius: 50px;
  transition: 0.3s;
  background: #5142fc;
}

.bid__btn:hover {
  background: #fff;
  color: #5142fc;
}

.history__link a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.772);

  font-size: 0.9rem;
}

.history__link a:hover {
  color: #fff;
}

.live__auction__top h3 {
  color: #fff;
}

.live__auction__top span a {
  text-decoration: none;
  color: #fff;
  border-bottom: 1px solid #5142fc;
}

.seller__section-title h3 {
  color: #fff;
}

.seller__img {
  width: 80px;
  height: 80px;
}

.seller__content h6 {
  font-size: 1rem;
  color: #fff;
}

@media only screen and (max-width: 992px) {
  .seller__content h6 {
    font-size: 0.8rem !important;
  }
}

.trending__title {
  color: #fff;
}

.single__step__item {
  background: #343444;
  border-radius: 5px;
  padding: 30px;
}

.step__title {
  color: #fff;
}

.step__item__content h5 a {
  text-decoration: none;
  color: #fff;
}

.single__step__item span i {
  color: #fff;
  padding: 15px;
  font-size: 1.5rem;
  background: #5142fc;
  border-radius: 5px;
}

.step__item__content {
  margin-top: 20px !important;
}

@media only screen and (max-width: 992px) {
  .step__item__content h5 a {
    font-size: 1rem;
  }

  .single__step__item p {
    font-size: 0.8rem;
    line-height: 25px;
  }
}

.common__section {
  background: url(/static/media/bg.d631560b.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 150px;
}

.common__section h2 {
  color: #fff;
}

.all__category__filter select,
.all__items__filter select,
.filter__right select {
  padding: 8px 25px;
  font-size: 0.9rem;
  color: #fff;
  cursor: pointer;
  border: none;
  outline: none;
  background: #343444;
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .all__category__filter select,
  .all__items__filter select,
  .filter__right select {
    padding: 5px 20px;
    font-size: 0.6rem;
  }
}

.form__input input,
.form__input textarea {
  width: 100%;
  padding: 7px 25px;
  color: #fff;
  font-size: 0.8rem;
  background: transparent;
  border: 1px solid rgba(221, 221, 221, 0.171);
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.form__input {
  margin-bottom: 15px;
}

.form__input label {
  color: #fff;
  margin-bottom: 10px;
}

.form__input .upload__input {
  padding: 40px 25px;
}

::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(1);
          filter: invert(1);
  cursor: pointer;
}

@media only screen and (max-width: 576px) {
  .create__item {
    margin-top: 15px;
  }
}

.wallet__item {
  background: #343444;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.wallet__item span {
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  display: block;
  margin: auto;
}

.wallet__item span i {
  font-size: 2.5rem;
}

.wallet__item h5 {
  color: #fff;
  margin-top: 15px;
}

.wallet__item p {
  font-size: 0.8rem;
  line-height: 28px;
}

.single__nft-img {
  border-radius: 8px;
}

.single__nft-seen span i {
  color: #fff;
  padding: 5px;
  background: #000;
  border-radius: 50px;
  cursor: pointer;
}

.single__nft-seen span,
.single__nft-more span {
  color: #fff;
  font-size: 0.8rem;
}

.single__nft-more span i {
  background: #343444;
  padding: 5px;
  cursor: pointer;
  border-radius: 50px;
}

.nft__creator {
  background: #343444;
  padding: 15px;
  border-radius: 7px;
  width: 50%;
}

.creator__img {
  width: 40px;
  height: 40px;
}

.creator__detail p {
  font-size: 0.7rem;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 0;
}

.creator__detail h6 {
  color: #fff;
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 500;
}

.singleNft-btn {
  padding: 8px 25px;
  background: #5142fc;
  border: none;
  outline: none;
  border-radius: 50px;

  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.singleNft-btn a {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
}

.singleNft-btn:hover {
  background: #fff;
}

.singleNft-btn:hover a {
  color: #5142fc;
}

.singleNft-btn i {
  color: #fff;
}

.singleNft-btn:hover i {
  color: #5142fc;
}

@media only screen and (max-width: 992px) {
  .single__nft__content h2 {
    font-size: 1.3rem;
  }

  .single__nft__content p {
    font-size: 0.8rem;
    line-height: 28px;
  }

  .nft__creator {
    width: 100%;
  }

  .singleNft-btn a {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 576px) {
  .single__nft__content h2 {
    margin-top: 15px;
  }
}

.header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111111;
}

.header__shrink {
  background: #14141f;
  box-shadow: 2px 2px 4px -2px #e250e5;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav__list {
  display: flex;
  align-items: center;
  grid-column-gap: 2.7rem;
  -webkit-column-gap: 2.7rem;
          column-gap: 2.7rem;
  margin-bottom: 0;
}

.nav__item a {
  text-decoration: none;
  color: #fff;
}

.nav__item a:hover {
  color: #fff;
}

.active {
  color: #e250e5 !important;
}

.logo h2 {
  background: linear-gradient(-45deg, #e250e5, #4b50e6, #e250e5, #4b50e6);
  background-size: 100% 100%;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-stroke: 3px transparent;
  -webkit-text-fill-color: #14141f;
  margin-bottom: 0;
}

.nav__right .btn {
  padding: 7px 25px;
  border: 1px solid #5142fc;
  border-radius: 50px;
}

.nav__right .btn a {
  color: #fff;
  text-decoration: none;
  font-size: 0.8rem;
}

.nav__right .btn span i {
  color: #fff;
}

.nav__right .btn a:hover {
  color: #fff;
}

.mobile__menu {
  color: #fff;
  font-size: 1.3rem;
  display: none;
}

@media only screen and (max-width: 768px) {
  .nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#14141f83, #14141f83);
    z-index: 222222;
    display: none;
  }

  .active__menu {
    display: block;
  }
  .nav__list {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #343444;
    z-index: 333333;
    flex-direction: column;
    padding-top: 40px;
  }

  .mobile__menu {
    display: block;
  }

  .btn {
    padding: 4px 25px !important;
  }

  .btn a {
    font-size: 0.7rem !important;
  }
}

.footer h5 {
  font-size: 1.1rem;
  color: #fff;
}

.footer {
  padding-bottom: 30px;
  background: #000;
}

.list__item {
  background: transparent !important;
  padding-left: 0 !important;
  border: none !important;
}

.list__item a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.772);

  font-size: 0.9rem;
}

.list__item a:hover {
  color: rgba(255, 255, 255, 0.772);
}

.newsletter {
  width: 100%;
  border-radius: 50px;
  color: #fff;
  padding: 7px 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  outline: none;
  font-size: 0.8rem;
  background: #343444;
}

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.772);
}

::placeholder {
  color: rgba(255, 255, 255, 0.772);
}

.social__links span a {
  text-decoration: none;

  color: rgba(255, 255, 255, 0.772);

  font-size: 1.2rem;
  font-weight: 400;
  transition: 0.3s;
}

.social__links span a:hover {
  color: #e250e5;
}

.copyright {
  font-size: 0.8rem;
}

