.common__section {
  background: url("../../../assets/images/bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 150px;
}

.common__section h2 {
  color: #fff;
}
