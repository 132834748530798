.form__input input,
.form__input textarea {
  width: 100%;
  padding: 7px 25px;
  color: #fff;
  font-size: 0.8rem;
  background: transparent;
  border: 1px solid rgba(221, 221, 221, 0.171);
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.form__input {
  margin-bottom: 15px;
}

.form__input label {
  color: #fff;
  margin-bottom: 10px;
}

.form__input .upload__input {
  padding: 40px 25px;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: pointer;
}

@media only screen and (max-width: 576px) {
  .create__item {
    margin-top: 15px;
  }
}
